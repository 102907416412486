import React from 'react'
import Background from "../Images/Programming.png"

const Services = [
    { name: 'Game Development', href: '#game' },
    { name: 'App Development', href: '#app' },
    { name: 'Web Development', href: '#web' },
    { name: 'AR/VR', href:  '#vr'}
]

function Hero() {
  return (
    <div className='flex relative isolate overflow-hidden py-14 mx-auto bg-slate-800'>
        <img
            src={Background}
            className='absolute inset-0 -z-10 h-full w-full object-cover opacity-20'
        />
        <div className='flex flex-col mx-auto justify-center'>
            <div className='flex justify-center mx-auto w-2/3'>
                <h1 className='text-blue-200 flex text-4xl md:text-6xl py-5 text-center'>
                    Creating Digital Presence for Small Businesses
                </h1>
            </div>
            <div className='hidden lg:flex lg:flex-row justify-center text-center mx-auto'>
                {Services.map((item) => (
                    <a
                        key={item.name}
                        href={item.href}
                        className='w-40 relative rounded-full m-2 bg-slate-600 px-3 py-1 text-sm leading-6 ring-1 ring-white text-white'
                    >
                        {item.name}
                    </a>
                    ))}
            </div>
        </div>
                
    </div>
  )
}

export default Hero