import React, {useState} from 'react'
import ContactForm from '../Components/ContactForm'
import Confirmation from '../Components/Confirmation'

function Contact() {
  
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormSubmit = (formData) => {
    // Here you can handle form submission, e.g., send it to an API
    console.log('Form submitted:', formData);
    // After handling submission, update the state to show confirmation
    setIsSubmitted(true);
  };
  
  return (
    <div className='flex flex-col mx-auto w-screen md:w-3/4 py-5'>
      <div>
        <h1 className='text-4xl text-blue-700 py-5 mx-auto'>Contact Us</h1>
        <p className='text-2xl mx-auto'>Do you want more information on our capabilities?</p>
        <p className='text-2xl mx-auto py-2 pb-10 text-slate-800'>Reach out to us today!</p>
      </div>
      
      <div>
        {isSubmitted ? (
          <Confirmation />
        ) : (
          <ContactForm onSubmit={handleFormSubmit} />
        )}
      </div>
    </div>
  )
}

export default Contact