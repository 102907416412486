import React from 'react'
import Controller from '../Images/Controller.png'
import AppDev from '../Images/AppDev.png'
import WebDev from '../Images/WebDevelopment.png'
import VR from '../Images/VR.png'

function Services() {
  return (
    <div id='services' className='my-5 md:w-3/4 mx-auto'>
        <p className='text-4xl text-center mx-auto'>Our Services</p>
        {/* Game Development */}
        <div id='game' className='flex flex-col justify-center text-center md:text-left border-2 rounded-xl p-4 shadow-md m-4'>
            <div className='flex flex-row'>
                <div className='space-y-2'>
                    <div className='text-2xl'>Game Development</div>
                    <p className='m-2'>Need help developing your next big game idea? Look no further for expert game development consulting. From concept to launch, we'll guide you through the process and help bring your game to life. We also provide technical support services for those who are just looking to overcome a hurdle. Let's turn your vision into a reality together.</p>
                </div>
                <img src={Controller} className='hidden md:flex rounded-lg h-40 w-40 mx-auto'/>
            </div>
            
            <a href='https://www.jimdublace.com' className='bg-blue-700 font-comfortaa text-cyan-400 text-xl rounded-2xl p-2 m-2 w-60'>JimDublace Studios</a>
        </div>
        {/* Web Development */}
        <div id='web' className='flex flex-col-reverse md:flex-row justify-center text-center md:text-left border-2 rounded-xl p-4 shadow-md m-4'>
            <div className='space-y-2'>
                <div className='text-2xl'>Web Development</div>
                <p className='m-2'>Do you need a landing page? There's no need to pay the hefty monthly fees from those other guys. Our websites are lightweight and low maintenance. We can also help you set up custom services to support your business like appointment booking, online marketplaces, and any other service your customers need to help them stay connected.</p>
            </div>
            <img src={WebDev} className='flex rounded-lg h-40 w-40 mx-auto'/>
        </div>
                {/* App Development */}
                <div id='app' className='flex flex-col-reverse md:flex-row justify-center text-center md:text-left border-2 rounded-xl p-4 shadow-md m-4'>
            <div className='space-y-2'>
                <div className='text-2xl'>App Development</div>
                <p className='m-2'>Are you ready to go mobile? We can help put your business in the palm of your customer's hands. No idea is too big or too small, and our toolkits allow for easy customization down the road as your business grows.</p>
            </div>
            <img src={AppDev} className='flex rounded-lg h-40 w-40 mx-auto'/>
        </div>
        {/* AR/VR */}
        <div id='vr' className='flex flex-col-reverse md:flex-row justify-center text-center md:text-left border-2 rounded-xl p-4 shadow-md m-4'>
            <div className='space-y-2'>
                <div className='text-2xl'>AR/VR</div>
                <p className='m-2'>Augmented Reality and Virtual Reality are the future of technology. We can help you develop custom AR/VR experiences for your business. Whether you're looking to create a virtual showroom, a custom training experience, or a new way to interact with your customers, we have the tools to help you succeed.</p>
            </div>
            <img src={VR} className='flex rounded-lg h-40 w-40 mx-auto'/>
        </div>
    </div>
  )
}

export default Services