import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';



  

function ContactForm() {

const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_oq3qiqi', 'template_ikhdi0x', form.current, {
        publicKey: 'qsXObpCSyLZS5GqZJ',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    form.current.reset();
};

return (
    <div className='flex flex-col mx-auto w-full'>
        <form ref={form} onSubmit={sendEmail}>
          <div className='flex flex-row'>
            <label className='form-label'>Name</label>
            <input type="text" name="from_name" className='form-text'/>
          </div>
          <div className='flex flex-row'>
            <label className='form-label'>Email</label>
            <input type="email" name="user_email" className='form-text'/>
          </div>
          <div className='flex flex-row'>
            <label className='form-label'>Message</label>
            <textarea name="message" className='form-text'/>
          </div>
          
          <button className='border-blue-700 border-2 p-2 px-5 text-3xl m-2 rounded-xl' type='submit'>Send</button>
          
        </form>
    </div>
    
  );
};

export default ContactForm;